<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="table"
          title="시퀀스 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getDeptList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addDept" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteDept"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveDept"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="시퀀스ID"
                  name="sequenceId"
                  autofocus
                  v-model="data.sequenceId">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="시퀀스명"
                  name="sequenceNm"
                  v-model="data.sequenceNm">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="현재값"
                  name="currentVal"
                  v-model="data.currentVal">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  label="prefix 단어"
                  name="prefix"
                  v-model="data.prefix">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="숫자 자리수"
                  type="number"
                  name="numLength"
                  v-model="data.numLength">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="증가율"
                  type="number"
                  name="increaseRate"
                  v-model="data.increaseRate">
                </c-text>
              </div>
              <div class="col-12">
                <c-radio
                  :editable="editable && dataeditable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable && dataeditable"
                  label="설명"
                  name="description"
                  v-model="data.description">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sequence-manager',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'sequenceId',
            field: 'sequenceId',
            label: '시퀀스ID',
            align: 'left',
            sortable: true,
          },
          {
            name: 'sequenceNm',
            field: 'sequenceNm',
            label: '시퀀스명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'currentVal',
            field: 'currentVal',
            label: '현재값',
            align: 'right',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'prefix',
            field: 'prefix',
            label: 'prefix 단어',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'numLength',
            field: 'numLength',
            label: '숫자 자리수',
            align: 'right',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'increaseRate',
            field: 'increaseRate',
            label: '증가율',
            align: 'right',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        sequenceId: '',
        sequenceNm: '',
        currentVal: '',
        prefix: '',
        numLength: '',
        increaseRate: '',
        useFlag: '',
        description: '',
      },
      searchParam: {
        useFlag: 'Y',
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sys.sequence.insert.url,
      saveType: 'POST',
      useFlagItems: [],
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.sequence.list.url;
      this.detailUrl = selectConfig.sys.sequence.get.url;
      this.checkUrl = selectConfig.sys.sequence.check.url;
      this.insertUrl = transactionConfig.sys.sequence.insert.url;
      this.updateUrl = transactionConfig.sys.sequence.update.url;
      this.deleteUrl = transactionConfig.sys.sequence.delete.url;
      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDeptList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.sequenceId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addDept() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        sequenceId: '',
        sequenceNm: '',
        currentVal: '0',
        prefix: '',
        numLength: '',
        increaseRate: '1',
        useFlag: 'Y',
        description: '',
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        sequenceId: null,
        sequenceNm: null,
        currentVal: null,
        prefix: null,
        numLength: null,
        increaseRate: null,
        useFlag: null,
        description: null,
      };
    },
    saveDept() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.sequenceId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message:
                '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteDept() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.sequenceId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ sequenceId: result.data.sequenceId })
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.reset();
    },
  }
};
</script>
